export default {
  storeConfig: {
    baseUrl: "/crud/channelInfo",
  },

  getDisplayText: (data, name) => {
    const v = data[name];
    return v;
  },

  fields: [
    {
      name: "id",
      title: "id",
      editComponent: "DisplayField",
      createEnabled: false,
    },
    {
      name: "name",
      title: "通道名称",
      editComponent: "TextField",
    },
    {
      name: "usedFlag",
      title: "是否使用",
      editComponent: "SelectField",
      editComponentConfig: {
        selections: [
          { value: 0, name: "禁用" },
          { value: 1, name: "启用" },
        ],
        value: 0,
      },
    },
    {
      name: "params",
      title: "params",
      editComponent: "TextField",
    },

    {
      name: "type",
      title: "通道类型",
      editComponent: "SelectFieldV1",
      editComponentConfig: {
        selectionsUrl: "/public/dict/selections/channelType",
        name: "type",
        textName: "text",
      },
    },
    {
      name: "timeout",
      title: "超时(毫秒)",
      editComponent: "TextField",
    },
  ],
};
