import { DictManager } from "local-vue-common/dictmanager/dictmanager";

const CHECK_METHOD_DICT_ID = "checkMethod";
DictManager.addDict(CHECK_METHOD_DICT_ID, {
  "check-close-count": "闭环计数检测",
  "check-node": "客栓检测",
});

export default {
  storeConfig: {
    baseUrl: "/crud/pipeline",
    findUrl: "/api/pipeline/find",
    findOneUrl: "/api/pipeline/findOne",
  },

  getDisplayText: (data, name) => {
    const v = data[name];
    if (name == CHECK_METHOD_DICT_ID) {
      return DictManager.getDictText(CHECK_METHOD_DICT_ID, v);
    }
    return v;
  },
  tableColumns: ["id", "startName", "endName", "checkMethod"],

  defaultRecord() {
    return {
      checkMethod: "check-close-count",
    };
  },

  fields: [
    {
      name: "id",
      title: "id",
      editComponent: "DisplayField",
      createEnabled: false,
    },

    {
      name: "startName",
      title: "起点名称",
      editComponent: "TextField",
    },
    {
      name: "endName",
      title: "终点名称",
      editComponent: "TextField",
    },
    {
      name: "checkMethod",
      title: "检测方法",
      editComponent: "SelectField",
      editComponentConfig: {
        selections: [
          { value: "check-close-count", name: "闭环计数检测" },
          { value: "check-node", name: "客栓检测" },
        ],
        value: "check-close-count",
      },
    },
    {
      name: "checkFlag",
      title: "是否检测",
      editComponent: "SelectField",
      editComponentConfig: {
        selections: [
          { value: 0, name: "不检测" },
          { value: 1, name: "检测" },
        ],
        value: 0,
      },
    },
    {
      name: "checkParam",
      title: "检测参数",
      editComponent: "PopupSelectField",
      editComponentConfig: {
        selectPanelName: "PipelineParamPanel",
        selectButtonLabel: "===",
      },
    },

    {
      name: "checkInterval",
      title: "检测间隔(分钟)",
      editComponent: "SelectField",
      editComponentConfig: {
        selections: [
          { value: 1, name: "1" },
          { value: 5, name: "5" },
        ],
        value: 5,
      },
    },
    {
      name: "alertInterval",
      title: "报警间隔(分钟)",
      editComponent: "TextField",
      editComponentConfig: {
        value: 5,
      },
    },

    {
      name: "nodes",
      title: "节点",
      editComponent: "PopupSelectField",
      editComponentConfig: {
        selectPanelName: "PipelineNodePanel",
        selectButtonLabel: "===",
        textName: "nodeNames",
      },
    },
    {
      name: "users",
      title: "通知用户",
      editComponent: "PopupSelectField",
      editComponentConfig: {
        selectPanelName: "PipelineUserPanel",
        selectButtonLabel: "===",
        textName: "userNames",
      },
    },

    {
      name: "domainId",
      title: "domainId",
      editComponent: "TextField",
      roles: ['ADMIN']
    }
  ],
};
