<template>
  <Dialog
    class="col-12 md:col-6 select-dialog"
    v-model:visible="showSelectDialog"
    :modal="true"
    :closable="false"
  >
    <ConfirmDialog></ConfirmDialog>

    <template #header>
      <h2>{{ title }}</h2>
    </template>
    <div class="grid">
      <div class="col-12">
        <div class="p-fluid">
          <div class="field" v-for="node in nodeItems" v-bind:key="node.index">
            <div class="p-inputgroup select-field">
              <span class="p-inputgroup-addon">节点{{ node.index }}</span>
              <InputText readonly type="text" :value="node.name" />
              <Button label="..." @click="openDialog(node.index)" />
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <Button label="确定" class="ml-3 w-3" @click="acceptSelect"></Button>
          <Button label="移除" class="ml-3 w-3" @click="removePoint"></Button>
          <Button label="取消" class="ml-3 w-3" @click="cancelSelect"></Button>
        </div>
      </div>
      <Dialog
        v-model:visible="showPointDialog"
        :closable="false"
        class="col-12 md:col-6 select-dialog"
        style="padding: 0"
      >
        <template #header> </template>
        <div class="grid">
          <div class="col-12">
            <div class="p-fluid">
              <div class="field">
                <label>节点-{{ currentNodeIndex }}</label>
                <InputText type="text" :value="getCurrentNodeText()" />
              </div>
              <div class="mb-3">
                <Listbox
                  v-model="selectedDataPoint"
                  :options="dataPoints"
                  optionLabel="name"
                  listStyle="height:500px"
                ></Listbox>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="grid">
            <div class="col text-center">
              <Button label="确定" class="w-6" @click="acceptDialog"></Button>
            </div>
            <div class="col text-center">
              <Button label="取消" class="w-6" @click="cancelDialog"></Button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </Dialog>
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";

import request from "local-vue-common/request/request";
const _ = require("lodash");
export default {
  name: "PipelineNodePanel",
  props: {
    record: {},
    name: {},
    editMode: {},
  },
  components: { ConfirmDialog },

  setup() {
    return {};
  },

  data() {
    return {
      currentNodeIndex: -1,
      currentNodePointId: 0,
      showSelectDialog: false,
      showPointDialog: false,
      nodePoints: [],
      nodeItems: [],
      dataPoints: [],
      selectedDataPoint: [],
      originalRecord: {},
      changedRecord: {},
    };
  },

  mounted() {
    //console.log("===PipelineNode mount");
    this.showSelectDialog = true;
    this.originalRecord = _.cloneDeep(this.record);
    this.changedRecord = _.cloneDeep(this.record);
    const maxNodeCount = 9;
    this.nodeItems = [];
    for (let i = 0; i < maxNodeCount; i++) {
      this.nodeItems.push({
        index: i,
        name: "?",
      });
    }
    request.get("/crud/dataPointInfo/find?page_all=1", {}).then((resp) => {
      this.dataPoints = resp.data.content;
      //console.log("===this.dataPoints.length",this.dataPoints.length)
    });
    this.initNodes();
  },
  watch: {
    record() {
      this.initNodes();
    },
    dataPoints() {},
  },

  methods: {
    initNodes() {
      const nodes = this.changedRecord.nodes;
      request
        .post("/crud/dataPointInfo/findIn", {
          name: "id",
          values: nodes,
        })
        .then((resp) => {
          this.nodePoints = resp.data.content;
          this.resetNodeName();
          for (let i = 0; i < nodes.length; i++) {
            this.updateNodeName(i, nodes[i], this.nodePoints);
            //this.nodeItems[i].name =
          }
        });
    },

    resetNodeName() {
      for (let i = 0; i < this.nodeItems.length; i++) {
        this.nodeItems[i].name = "";
      }
    },
    updateNodeName(index, id, points) {
      for (let i = 0; i < points.length; i++) {
        if (id == points[i].id) {
          this.nodeItems[index].name = points[i].name;
          return;
        }
      }
    },
    updateNodes(p) {
      let nodes = this.changedRecord.nodes;
      if (nodes.length > this.currentNodeIndex) {
        nodes[this.currentNodeIndex] = p.id;
      } else {
        while (nodes.length <= this.currentNodeIndex) nodes.push({});
        nodes[this.currentNodeIndex] = p.id;
      }
      this.changedRecord.nodes = nodes;
      this.initNodes();
    },
    getCurrentNodeText() {
      return this.nodeItems[this.currentNodeIndex].name;
    },
    openDialog(index) {
      this.currentNodeIndex = index;
      this.showPointDialog = true;
      //console.log("node index", index);
    },

    cancelDialog() {
      this.showPointDialog = false;
    },
    acceptDialog() {
      //console.log("===selectedDataPoint", this.selectedDataPoint);
      this.showPointDialog = false;
      this.updateNodes(this.selectedDataPoint);
    },
    cancelSelect() {
      //console.log("cancelSelect1", this.record, this.originalRecord);
      _.assign(this.record, this.originalRecord);
      //console.log("cancelSelect2", this.record, this.originalRecord);

      this.$emit("cancel");
    },
    acceptSelect() {
      const nodes = [];
      const nodeNames = [];
      for (let i = 0; i < this.changedRecord.nodes.length; i++) {
        const dataId = this.changedRecord.nodes[i];
        if (dataId > 0) {
          nodes.push(this.changedRecord.nodes[i]);
          nodeNames.push(this.nodeItems[i].name);
        }
      }
      //console.log("PipelineNode accept",nodes)
      this.$emit("accept", nodes, nodeNames);
    },

    removePoint() {
      this.$confirm.require({
        message: "移除一个节点吗?",
        header: "确认",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
          this.execRemovePoint();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    execRemovePoint() {
      if (this.changedRecord.nodes.length > 1) {
        const nodes = [];
        for (let i = 0; i < this.changedRecord.nodes.length - 1; i++) {
          nodes.push(this.record.nodes[i]);
        }
        this.changedRecord.nodes = nodes;
        this.initNodes();
      }
    },
  },
};
</script>

<style scoped></style>
